import React from 'react';
import { Icon } from '@/components';
import { MenuDataItem } from '@ant-design/pro-components';

const MenuConfig: MenuDataItem[] = [
	{
		name: '商品',
		icon: <Icon type="icon-shangpin" style={{ fontSize: '20px' }} />,
		key: 'goods',
		path: '/wares/list',
		children: [
			{
				name: '商品库',
				key: '/wares/list',
				path: '/wares/list'
			},
			{
				name: '审核记录',
				key: '/wares/examine',
				path: '/wares/examine'
			}
		]
	},
	{
		name: '订单管理',
		icon: <Icon type="icon-kehushezhi" style={{ fontSize: '20px' }} />,
		key: 'sale',
		children: [
			//   {
			//   	name: '销售订单',
			//   	key: '/sale/saleOrder',
			//   	path: '/sale/saleOrder'
			//   },
			{
				name: '订单',
				key: '/wares/orderDispatch',
				path: '/wares/orderDispatch'
			},
			{
				name: '售后订单',
				key: '/sale/afterSale',
				path: '/sale/afterSale'
			}
		]
	},
	{
		name: '财务管理',
		icon: <Icon type="icon-caiwuguanli-" style={{ fontSize: '20px', color: '#333' }} />,
		key: 'billManage',
		children: [
			{
				name: '代发账单',
				key: '/wares/billForwarding',
				path: '/wares/billForwarding'
			}
		]
	},
	{
		name: '结算管理',
		icon: <Icon type="icon-caiwuguanli-" style={{ fontSize: '20px', color: '#333' }} />,
		key: 'finace',
		children: [
			{
				name: '待结算',
				key: '/module/finace/pendingSettlement',
				path: '/module/finace/pendingSettlement'
			},
			{
				name: '结算申请单',
				key: '/module/finace/settlementRequest',
				path: '/module/finace/settlementRequest'
			},
			{
				name: '发票',
				key: '/module/finace/invoice',
				path: '/module/finace/invoice'
			}
		]
	},
	{
		name: '询价管理',
		icon: <Icon type="icon-xunjia" style={{ fontSize: '20px' }} />,
		key: 'inquiryManage',
		children: [
			{
				name: '报价列表',
				key: '/wares/inquiryManage',
				path: '/wares/inquiryManage'
			}
			// {
			// 	name: '采购询价',
			// 	key: '/wares/inquiryManage/inquiryAdd',
			// 	path: '/wares/inquiryManage/inquiryAdd'
			// }
		]
	},
	{
		name: '设置',
		icon: <Icon type="icon-shezhi" style={{ fontSize: '20px' }} />,
		key: 'setting',
		children: [
			{
				name: '运费模板',
				key: '/wares/freight-template',
				path: '/wares/freight-template'
			},
			{
				name: '地址管理',
				key: '/wares/send-address',
				path: '/wares/send-address'
			},
			{
				name: '编码规则',
				key: '/wares/coding',
				path: '/wares/coding'
			},
			{
				name: '企业信息',
				key: '/wares/companyInfo',
				path: '/wares/companyInfo'
				// children: [
				// 	{
				// 		name: '企业信息详情',
				// 		key: '/wares/companyInfo/detail',
				// 		path: '/wares/companyInfo/detail'
				// 	},
				// 	{
				// 		name: '企业信息编辑',
				// 		key: '/wares/companyInfo/edit',
				// 		path: '/wares/companyInfo/edit'
				// 	}
				// ]
			}
		]
	}
];

export default MenuConfig;
