import React, { useState, useEffect, FC } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Modal, Space } from 'antd';
import { locationStore } from '@/stores';
import './index.less';

const ChooseAddress: FC<any> = (props) => {
  const { visible, onOk, onCancel, value } = props;
  const navigate = useNavigate();

  const [addressList, setAddressList] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>(undefined);

  useEffect(() => {
    setSelectedItem(value);
    _getData();
  }, []);

  const _getData = async () => {
    const res = await locationStore.getLocationList({ type: 2 });
    setAddressList(res);
  };

  const confirm = () => {
    onOk(selectedItem);
    onCancel();
  };

  return (
    <Modal
      title={
        <Space>
          <span>选择地址</span>
          <Button
            type="primary"
            onClick={() => {
              navigate({
                pathname: '/setting/location',
              });
            }}>
            添加新地址
          </Button>
        </Space>
      }
      open={visible}
      centered
      width={700}
      okText="确定保存"
      onOk={confirm}
      onCancel={onCancel}>
      {addressList.map((item: any) => (
        <div key={item.key} className="address_item">
          <span className="address_content">{`${item.fullAddress} ${item.consignee}${
            item.mobileNumber ? '，' + item.mobileNumber : ''
          }${item.landlineNumber ? '，' + item.landlineNumber : ''}`}</span>
          {selectedItem && item.id === selectedItem.id ? (
            <Button disabled className="address_btn">
              已选择
            </Button>
          ) : (
            <Button type="primary" className="address_btn" onClick={() => setSelectedItem(item)}>
              选择
            </Button>
          )}
        </div>
      ))}
    </Modal>
  );
};

ChooseAddress.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.object,
};

export default ChooseAddress;
