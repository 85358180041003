(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "axios"], factory);
	else if(typeof exports === 'object')
		exports["home"] = factory(require("React"), require("ReactDOM"), require("axios"));
	else
		root["home"] = factory(root["React"], root["ReactDOM"], root["axios"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4883__, __WEBPACK_EXTERNAL_MODULE__81845__, __WEBPACK_EXTERNAL_MODULE__62742__) => {
return 