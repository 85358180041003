import React, { useEffect } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import classnames from 'classnames';

import '../SkuTable/index.less';

interface otherTheadProps {
  key: string;
  name: string;
  isRequired: boolean;
  max?: number;
  min?: number;
  precision?: number;
}

interface GoodsScopeTableProps {
  unit?: string;
  value?: any[];
  otherThead?: any[];
  contractMin?: number;
  contractMax?: number;
  onChange?: Function;
  previewText?: string;
  isReadOnly?: boolean;
}

// 获取表头
const getThead = (otherThead: otherTheadProps[], previewText: string, isReadOnly: boolean = false) => {
  return (
    <thead>
      <tr>
        {!isReadOnly && (
          <th>
            <span>操作</span>
          </th>
        )}
        {otherThead.map((item: otherTheadProps) => (
          <th key={item.key}>
            <span>
              {item.isRequired && <em className="required">*</em>}
              {item.name}
            </span>
          </th>
        ))}
        <th style={{ minWidth: 200 }}>
          <span>{previewText}</span>
        </th>
      </tr>
    </thead>
  );
};

// 获取表内容
const getTbody = (
  data: any[],
  otherThead: otherTheadProps[],
  onChange: Function,
  contractMin: number,
  contractMax: number,
  unit: string,
  isReadOnly: boolean = false
) => {
  const getNumber = (index: number) => {
    let _number = data[index].number;
    let maxNumber = contractMax;
    if (data[index + 1]?.number && data[index + 1]?.shipmentDay) {
      maxNumber = data[index + 1].number - 1;
    }
    if (_number < maxNumber) {
      return `${_number} - ${maxNumber}`;
    } else {
      return _number;
    }
  };

  data = !!data.length ? data : [{ number: null, shipmentDay: null }];

  return (
    <tbody>
      {data.map((child: any, i: number, arr) => (
        <tr key={i}>
          {!isReadOnly && (
            <td>
              <Space>
                <Button
                  type="link"
                  size="small"
                  disabled={arr.length === 3}
                  onClick={() => arr.length < 3 && onChange('add')}>
                  <PlusOutlined />
                </Button>
                <Button type="link" size="small" disabled={arr.length === 1} onClick={() => onChange('remove', i)}>
                  <MinusOutlined />
                </Button>
              </Space>
            </td>
          )}
          {otherThead.map((theadItem: otherTheadProps, x: number) => {
            let errorText = '';
            if (
              typeof child[theadItem.key] === 'number' &&
              !!contractMax &&
              !(i === 0 && x === 0) &&
              theadItem.key === 'number'
            ) {
              if ((i > 0 && child[theadItem.key] <= arr[i - 1][theadItem.key]) || child[theadItem.key] < contractMin) {
                errorText = '订货数量必须递增填写';
              } else if (child[theadItem.key] > contractMax) {
                errorText = `最大订货数量不得大于${contractMax}`;
              }
            }
            const isRequired =
              theadItem.isRequired &&
              !child[theadItem.key] &&
              !(i === 0 && x === 0) &&
              typeof child[`${theadItem.key}_required`] === 'boolean';

            return (
              <td key={theadItem.key}>
                <div
                  className={classnames('field', {
                    error: !!errorText || isRequired,
                  })}>
                  {child[theadItem.key]}
                </div>
              </td>
            );
          })}

          <td>
            {!!child?.number && !!contractMax && !!child.shipmentDay && (
              <span>{`${getNumber(i)}${unit}：预计 ${child.shipmentDay} 天`}</span>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

interface reserveListTypes {
  number: number | null;
  shipmentDay: number | null;
}

const GoodsScopeTable: React.FC<GoodsScopeTableProps> = ({
  unit = '箱',
  value = [],
  otherThead = [{ number: null, shipmentDay: null }],
  contractMin = 0,
  contractMax = 0,
  previewText = '预览',
  onChange = () => {},
  isReadOnly = false,
}) => {
  useEffect(() => {
    if (!!contractMin) {
      inputChange('edit', 0, {
        ...value[0],
        number: contractMin,
      });
    }
  }, [contractMin, contractMax]);

  const inputChange = (type: 'add' | 'edit' | 'remove', index?: number, item?: reserveListTypes) => {
    let _reserveList = [...value];

    switch (type) {
      case 'add':
        _reserveList.push({ number: null, shipmentDay: null });
        break;
      case 'remove':
        if (index !== undefined) {
          _reserveList.splice(index, 1);
          if (index === 0) {
            _reserveList[0].number = contractMin;
          }
        }
        break;
      default:
        if (index !== undefined && !!item) {
          _reserveList[index] = item;
        }
    }

    onChange(_reserveList);
  };

  return (
    <div className="fx__goods-score-table">
      <table className="form-table">
        {getThead(otherThead, previewText, isReadOnly)}

        {getTbody(value, otherThead, inputChange, contractMin, contractMax, unit, isReadOnly)}
      </table>
    </div>
  );
};
export default React.memo(GoodsScopeTable);
