import React, { useMemo } from 'react';
import { ConfigProvider, Space, Tag, Tooltip, type TooltipProps } from 'antd';

import './index.less';

export interface TagGroupProps {
	className?: string;
	style?: React.CSSProperties;
	tags: Array<{
		flag: string;
		id: string;
		weight: number;
	}>;
	maxShowCount?: number;
	tooltipProps?: Omit<TooltipProps, 'title'>;
}

export default function TagGroup(props: TagGroupProps) {
	const { className, style, tags, maxShowCount = 2, tooltipProps } = props;

	const { showTags, hiddenTags } = useMemo(() => {
		return {
			showTags: tags.slice(0, maxShowCount),
			hiddenTags: tags.slice(maxShowCount)
		};
	}, [tags, maxShowCount]);

	return (
		<ConfigProvider
			prefixCls="fle"
			theme={{
				token: {
					colorBgSpotlight: '#fff'
				}
			}}>
			<Space className={`fle-tag-group ${className}`} style={style} wrap size={8}>
				{showTags.map((tag: any) => {
					return (
						<Tag style={tag.weight === 50 ? { color: '#E37815', background: '#fcf0e5' } : {}} key={tag.id}>
							{tag.flag}
						</Tag>
					);
				})}
				{hiddenTags.length > 0 ? (
					<Tooltip
						title={
							<Space className="fle-tag-group" wrap size={8}>
								{hiddenTags.map((tag: any) => (
									<Tag style={tag.weight === 50 ? { color: '#E37815', background: '#fcf0e5' } : {}} key={tag.id}>
										{tag.flag}
									</Tag>
								))}
							</Space>
						}
						{...tooltipProps}
						rootClassName={`fle-tag-group-tooltip ${tooltipProps?.rootClassName || ''}`}>
						<Tag color="processing">+{hiddenTags.length}</Tag>
					</Tooltip>
				) : null}
			</Space>
		</ConfigProvider>
	);
}
