/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-04-01 11:29:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-08 12:04:47
 */
import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import './index.less';
// antd 字体图标
const IconFont = createFromIconfontCN({
  scriptUrl: [
    '//at.alicdn.com/t/font_2815636_zvckudog9b8.js',
    // 订单优化
    '//at.alicdn.com/t/c/font_3051549_aw3a6znu7d.js',
  ],
});

interface MainProps {
  [key: string]: any;
  type: string;
}
const Icon: React.FC<MainProps> = (props) => <IconFont {...props} />;
export default Icon;
