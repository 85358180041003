// store
import StoreAddAddressModal from "./AddAddress";
import StoreChooseAreaModal from "./ChooseArea";
import StoreChooseAddressModal from "./ChooseAddress";

export default {
  // store
  StoreAddAddressModal,
  StoreChooseAreaModal,
  StoreChooseAddressModal,
}
