import { makeAutoObservable, action } from 'mobx';
import $http from '@/tools/service';
import commonApi from '@/api/common';
import { Modal } from 'antd';
import Cookies from 'js-cookie';

import type { StateProps } from '@/types/common/store';

class CommonStore {
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }

  updateState = (params: StateProps) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };

  memberInfo: any = {};
  queryInfo: any = {};
  isLoadingMemberInfo = false;

  getMemberInfo = async () => {
    try {
      const { data } = await $http.get(commonApi.get('get_member_info') as string);
      this.memberInfo = data || {};
    } catch (ex) {
      console.warn(ex);
    }
  };

  getQueryInfo = async () => {
    const paramsInfo: any = { loginType: '40' };
    try {
      this.isLoadingMemberInfo = true;
      const { data } = await $http.post(commonApi.get('get_query_info') as any, { ...paramsInfo });

      this.queryInfo = data || {};
      this.isLoadingMemberInfo = false;
    } catch (ex) {
      this.isLoadingMemberInfo = false;
      console.warn(ex);
    }
  };
  getSupplierLoginInfo = async (params: any) => {
    try {
      const res: any = await $http.post(commonApi.get('get_accountLogin') as string, params);
      console.log(res,'eee');
      
        return res
    } catch (ex) {
      console.warn(ex);
    }
  };
  getStructureEmployees = async (params: any = {}) => {
    try {
      // const data = await $http.get(commonApi['structure-employees'], { params });
      // console.log(data);
    } catch (ex) {
      console.warn(ex);
    }
  };

  // 获取地址

  getLocationLevel = async (params: any) => {
    const res: any = await $http.post(commonApi.get('get_address') as string, params);
    if (res.success) {
      return res.data.map((item: any) => ({
        name: item.name,
        id: item.code,
        level: item.level,
      }));
    }
    return [];
  };

  // 退出登录
  loginOut = async () => {
    return await $http.post(commonApi.get('login_out') as string);
  };


  
  getAccountInfo = async (params: {account: string}) => {
    return await $http.post(commonApi.get('get_accountInfo') as any, params);
  };
  

	/**
	 * 上传单个文件
	 */
	@action async uploadSingleFile(params: any) {
		let { data } = await $http.post('/basic/new/file/upload', params, {
			withCredentials: true,
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: '*/*'
			}
		});
		return data;
	}

  //获取采购负责人
  purchasePrincipal = async (params: any = {}) => {
    try {
      const data = await $http.post(commonApi.get('get_purchasePrincipal') as string, params);
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
}

export default new CommonStore();
