/*
 * @Author: your name
 * @Date: 2021-09-19 10:31:03
 * @LastEditTime: 2021-09-20 10:29:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /fx-peliten-basic-module/src/stores/supplier.ts
 */
import { makeAutoObservable } from "mobx";
import $http from "@/tools/service";
import goodsApi from "@/api/goods";
import { waitTime } from "@/tools";
import { message } from "antd";

import type { StateProps } from "@/types/common/store";

class GoodsStore {
  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }
  //查询商品列表
  getGoodsList = async (params: {}) => {
    try {
      await waitTime(1000);
      const data = await $http.post(goodsApi.POST_BASIC_GOODSLIST, {
        ...params,
      });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
  //查询驳回历史记录
  getGoodsRecordList = async (params: {}) => {
    try {
      await waitTime(1000);
      const data = await $http.post(goodsApi.POST_GOODS_RECORD_LIST, {
        ...params,
      });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //挑选商品列表

  getPickGoodsList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_PICK_GOODS_LIST, {
        ...params,
      });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
  //获取分类列表
  getcategoryIDList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_CATEGORYID_LIST, {
        ...params,
      });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品下架
  goodsOffSelt = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_OFFSELF, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品上架
  goodsOnSelt = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_ONDELF, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //供应商商品移除
  goodsRemove = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_GOODS_REMOVE, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //获取品类列表
  getFlagList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_FLAG_LIST, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品批量上架
  multipleOn = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_MULTIPLE_ON, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品批量下架
  multipleOff = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_MULTIPLE_OFF, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品
  tripGoodsPrice = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_CHECK_SHELF, { ...params });

      return data;
    } catch (ex: any) {
      message.error(ex);
    }
  };

  //获取运费模板列表
  getTemplateList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_TEMPLATE, { ...params });

      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //获取品牌列表
  getByIsList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_BRAND_LIST, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //草稿箱删除商品
  deleteGoods = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_DELETE_GOODS, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //获取发货地址列表
  getAddressList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_ADDRESS_LIST, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //批量设置草稿箱商品
  multipleConfigGoods = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_CONFING_GOODS, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品驳回
  goodsReject = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_REJECT_GOODS, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品变更列表

  goodsChangeList = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_GOODS_CHANGE_LIST, {
        ...params,
      });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品变更列表

  exportSource = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_EXPORT, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //商品变更详情

  goodsChangeInfo = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_CHANFE_INFO, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //操作人列表
  getChangeUserList = async () => {
    try {
      const data = await $http.post(goodsApi.POST_USER_LIST);
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
  //申请变更

  applayGoods = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_CHANGE_GOODS, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //导入文件

  importExcel = async (params: any) => {
    try {
      const formData = new FormData();
      const headers: any = { "Content-Type": "multipart/form-data" };
      for (const key in params) {
        formData.append(key, params[key]);
      }
      return $http.post(
        "/bestgift/supplier/goods/goods/importSelfGoods",
        formData,
        headers
      );
    } catch (ex) {
      console.warn(ex);
    }
  };

  //审核拒绝

  examineReject = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_EXAMINE_REJECT, {
        ...params,
      });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //审核通过
  examinePass = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_EXAMINE_PASS, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //修改列设置
  editColumn = async (type: "save" | "reset", params: {}) => {
    try {
      const data =
        type === "save"
          ? await $http.post(goodsApi.POST_EDIT_COLUMN, { ...params })
          : await $http.post(goodsApi.GET_REST_COLUMN, { ...params });

      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //获取列设置
  getColumnConfig = async (params: {}) => {
    try {
      const data = await $http.post(goodsApi.POST_GET_COLUMN, { ...params });
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
  //批量删除商品库商品
  batchDeleteGoods = async (params: {
    goodsIdList: any[];
    isSupplier?: number;
  }) => {
    try {
      const data = await $http.post(goodsApi.POST_BATCH_DELETE_GOODS, {
        ...params,
      });
      return data;
    } catch (error) {
      console.warn(error);
    }
  };
  //批量删除驳回商品库商品
  batchDeleteRejectGoods = async (params: {
    goodsIdList: any[];
    isSupplier?: number;
  }) => {
    try {
      const data = await $http.post(goodsApi.POST_BATCH_DELETE_REJECT_GOODS, {
        ...params,
      });
      return data;
    } catch (error) {
      console.warn(error);
    }
  };
  //商品分类
  getSubSupplierList = async () => {
    try {
      const data = await $http.get(goodsApi.GET_SUBSUPPLIERLIST);
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };

  //获取备用字段列表
  getExtList = async (params: { [key: string]: any }) => {
    try {
      const data = await $http.post(goodsApi.GET_EXTLIST, params);
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
  // 商品详情(会员视角)
  getGoodsDetail = async (params: { [key: string]: any }) => {
    try {
      const data = await $http.post(goodsApi.getGoodsDetail, params);
      return data;
    } catch (ex) {
      console.warn(ex);
    }
  };
}

export default new GoodsStore();
