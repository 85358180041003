import React from 'react';
import * as ReactDOM from 'react-dom/client';
import PlusConfigProvider from '@fle-ui/plus-config-provider';
import Router from '@/router';
import theme from '@fle-ui/plus-theme';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import '@/style/index.less';
import '@/theme/reset.less';
import 'animate.css';

dayjs.extend(duration)

const { defaultTheme } = theme;

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container!);

root.render(
  <PlusConfigProvider prefixCls='ant' theme={defaultTheme}>
    <Router />
  </PlusConfigProvider>
);
