import React, { useEffect, useState } from 'react';
import { MessageCenterPageApi } from '@/api/messageCenter';
import { List } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import '../index.less';

interface MessageBoxProps {
	messageType: string;
	tabType: string;
	setMessageOpen: () => void;
}
const appName = new Map([
	[10, '配置中心'],
	[20, '商品云'],
	[30, '方案云'],
	[40, '客户云'],
	[50, '营销云'],
	[60, '数据云'],
	[70, '供应链云']
]);
const appPlatform = new Map([
	[10, 'console'],
	[20, 'digitization'],
	[30, 'wonderful']
]);
let num = 1;
const MessageBox = (prop: MessageBoxProps) => {
	const navigate = useNavigate();
	const { messageType, tabType } = prop;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<any[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [pageNo, setPageNo] = useState<number>(1);
	const [showNoData, setShowNoData] = useState<boolean>(false);
	const loadMoreData = () => {
		if (loading) {
			return;
		}
		setLoading(true);
		getMesaggeDataList(false);
		setLoading(false);
	};
	const getMesaggeDataList = async (ifFirst: boolean) => {
		setShowNoData(false);
		const param = {
			channelType: 10,
			isRead: tabType === '0' ? null : tabType,
			pageNo: ifFirst ? 1 : pageNo,
			type: messageType,
			pageSize: 10
		};
		const res: any = await MessageCenterPageApi.remindGetListByPage(param);
		if (!res) return;
		if (ifFirst) {
			setData([]);
			setPageNo(2);
			setTimeout(() => {
				setData(res.data.list);
			}, 100);
		} else {
			setData([...data, ...res.data.list]);
			setPageNo(pageNo + 1);
		}
		if (res.data.total === 0) {
			setShowNoData(true);
		}
		setTotal(res.data.total);
	};
	const seeDetail = async (list: any[], fromProduct: number, remindId: string) => {
		const param = {
			remindIds: [remindId]
		};
		await MessageCenterPageApi.remindRead(param);
		prop.setMessageOpen();
		navigate(list[0]);
	};
	const readMessage = async (e: any, item: any) => {
		if (e.target.nodeName === 'A') {
			const param = {
				remindIds: [item.remindId]
			};
			await MessageCenterPageApi.remindRead(param);
		}
	};
	// 正则匹配
	const reg = (value: any, routeList: any[]) => {
		const pattern = /\{#(.*?)#\}/;
		let temp = value.content;

		routeList.forEach((value) => {
			const match = temp.match(pattern);
			temp = temp.replace(pattern, `<a href="${value}">${match?.[1]}</a>`);
		});
		return temp;
	};
	useEffect(() => {
		getMesaggeDataList(true);
		setData([]);
		setTotal(0);
	}, [messageType, tabType]);

	return (
		<>
			<div
				id="scrollableDiv"
				className="content-scroll"
				style={{
					height: 'calc(100vh - 180px)',
					overflow: 'auto'
				}}>
				<InfiniteScroll
					dataLength={data.length}
					next={loadMoreData}
					hasMore={data.length < total}
					loader={null}
					endMessage={
						<>
							{data && data.length > 0 && (
								<p style={{ color: '#AAAAAA', fontSize: 16, textAlign: 'center', marginTop: 20 }}>已经到底啦～</p>
							)}
						</>
					}
					scrollableTarget="scrollableDiv">
					<List
						dataSource={data}
						locale={{
							emptyText: showNoData && (
								<div className="message-no-data">
									<img
										className="no-data-img"
										src="https://oss.elebuys.com/tmpdir/202311081443060003739028.png"
										alt=""
									/>
									<p>还没有任何信息</p>
								</div>
							)
						}}
						renderItem={(item) => (
							<div className="message-box-root">
								<div className="message-app-name">{appName.get(item.fromModule)}</div>
								<div className="message-title-box">
									<div className="message-title">
										{item.title}
										{item.isRead === -10 && <div className="red-dom" />}
									</div>
									<div className="message-time">{item.createTime.split('T').join(' ')}</div>
								</div>
								<div
									className="message-content"
									onClick={(e) => {
										readMessage(e, item);
									}}
									dangerouslySetInnerHTML={{ __html: reg(item, item.routeList) }}
								/>
								<div
									className="message-see-detail"
									onClick={() => {
										seeDetail(item.routeList, item.fromProduct, item.remindId);
									}}>
									查看详情
								</div>
							</div>
						)}
					/>
				</InfiniteScroll>
			</div>
		</>
	);
};

export default MessageBox;
