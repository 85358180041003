import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./index.less";

const HeaderMenu: React.FC<any> = ({ menuData, changeMenu }) => {
  const [activeMenu, setActiveMenu] = useState<any>("saasSupplier");
  useEffect(() => {
    changeMenu(activeMenu);
  }, [activeMenu]);
  return (
    <div className="fle-layout-header-menu">
      {menuData.map((item: any) => {
        return (
          <div
            onClick={() => {
              setActiveMenu(item.key);
            }}
            key={item.key}
            className={classNames(
              activeMenu == item.key
                ? "fle-layout-header-menu-active fle-layout-header-menu-item"
                : "fle-layout-header-menu-normal  fle-layout-header-menu-item"
            )}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};
export default React.memo(HeaderMenu);
