export default [
	{
		//询价列表
		path: '/wares/inquiryManage',
		component: () => import('@/pages/inquiryManage')
	},
	{
		//询价编辑
		path: '/wares/inquiryManage/inquiryAdd',
		component: () => import('@/pages/inquiryManage/inquiryAdd')
	}
];
