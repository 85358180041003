import React from "react";
import { Icon } from "@/components";
import { MenuDataItem } from "@ant-design/pro-components";

const SupplierConfig: MenuDataItem[] = [
  {
    name: "商品",
    icon: <Icon type="icon-shangpin" style={{ fontSize: "20px" }} />,
    key: "goods",
    path: "/",
    children: [
      {
        name: "商品库",
        key: "/webview/supplier/publishList",
        path: "/webview/supplier/publishList",
      },
      {
        name: "录入商品信息",
        key: "/webview/product/add",
        path: "/webview/product/add",
      },
      {
        name: "批量导入商品",
        key: "/webview/products/adminSupplierBatchImport",
        path: "/webview/products/adminSupplierBatchImport",
      },
      {
        name: "信息变更申请记录",
        key: "/webview/productChange/examineList",
        path: "/webview/productChange/examineList",
      },
      {
        name: "门店管理",
        key: "/webview/SpShop/index",
        path: "/webview/SpShop/index",
      },
      {
        name: "码池管理",
        key: "/webview/CodePool/index",
        path: "/webview/CodePool/index",
      },
      {
        name: "核销管理",
        key: "/webview/WriteOff/pc",
        path: "/webview/WriteOff/pc",
      },
      {
        name: "区域管理",
        key: "/webview/supplier/supplierDistributionArea",
        path: "/webview/supplier/supplierDistributionArea",
      },
      {
        name: "商品统一说明",
        key: "/webview/product/supplierProducText",
        path: "/webview/product/supplierProducText",
      },
    ],
  },
  {
    name: "订单管理",
    icon: <Icon type="icon-kehushezhi" style={{ fontSize: "20px" }} />,
    key: "sale",
    children: [
      {
        name: "订单管理",
        key: "/webview/esOrder/index",
        path: "/webview/esOrder/index",
      },
      {
        name: "售后订单",
        key: "/webview/order/refundOrderList",
        path: "/webview/order/refundOrderList",
      },
    ],
  },
  {
    name: "结算",
    icon: (
      <Icon
        type="icon-caiwuguanli-"
        style={{ fontSize: "20px", color: "#333" }}
      />
    ),
    key: "billManage",
    children: [
      {
        name: "对账与结算",
        key: "/webview/capital/accountListV2",
        path: "/webview/capital/accountListV2",
      },
    ],
  },
  {
    name: "设置",
    icon: <Icon type="icon-shezhi" style={{ fontSize: "20px" }} />,
    key: "setting",
    children: [
      {
        name: "退货地址设置",
        key: "/webview/supplier/wlSetting",
        path: "/webview/supplier/wlSetting",
      },
    ],
  },
];

export default SupplierConfig;
