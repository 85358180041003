import React from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Space, InputNumber } from 'antd';
import classnames from 'classnames';

interface otherTheadProps {
	key: string;
	name: string;
	isRequired: boolean;
	max?: number;
	min?: number;
	precision?: number;
}

interface GoodsSectionTableProps {
	unit?: string;
	value?: any[];
	otherThead?: any[];
	onChange?: Function;
	previewText?: string;
	disabled?: boolean;
}

// 获取表头
const getThead = (otherThead: otherTheadProps[], previewText: string) => {
	return (
		<thead>
			<tr>
				{/* <th>
					<span>操作</span>
				</th> */}
				{otherThead.map((item: otherTheadProps) => (
					<th key={item.key}>
						<span>
							{item.isRequired && <em className="required">*</em>}
							{item.name}
						</span>
					</th>
				))}
				<th>
					<span>{previewText}</span>
				</th>
			</tr>
		</thead>
	);
};

// 获取表内容
const getTbody = (data: any[], otherThead: otherTheadProps[], onChange: Function, unit: string, disabled: boolean) => {
	const getNumber = (index: number) => {
		const minNumber = data[index].minNum;
		const maxNumber = data[index + 1]?.minNum - 1;

		if (minNumber < maxNumber) {
			return `${minNumber} - ${maxNumber}`;
		} else if (!maxNumber) {
			return `≥${minNumber}`;
		} else {
			return minNumber;
		}
	};

	const __data = data.length ? data : [{ minNum: null, salePrice: null }];

	return (
		<tbody>
			{__data.map((child: any, i: number, arr) => (
				<tr key={i}>
					{/* <td>
						<Space>
							<Button
								type="link"
								size="small"
								disabled={arr.length === 3 || disabled}
								onClick={() => arr.length < 3 && onChange('add')}>
								<PlusOutlined />
							</Button>
							<Button
								type="link"
								size="small"
								disabled={arr.length === 1 || disabled}
								onClick={() => onChange('remove', i)}>
								<MinusOutlined />
							</Button>
						</Space>
					</td> */}
					{otherThead.map((theadItem: otherTheadProps, x: number) => {
						const minErrorText =
							theadItem.key === 'minNum' && typeof child.minNum === 'number' && child.minNum <= arr[i - 1]?.minNum
								? '购买数量后者必须大于前者'
								: null;

						const salePriceError =
							theadItem.key === 'salePrice' &&
							typeof child.salePrice === 'number' &&
							child.salePrice <= arr[i + 1]?.salePrice
								? '销售单价前者必须大于后者'
								: null;

						const isRequired =
							theadItem.isRequired && !child[theadItem.key] && typeof child[`${theadItem.key}_required`] === 'boolean';

						return (
							<td key={theadItem.key}>
								<div
									className={classnames('field', {
										error: !!minErrorText || !!salePriceError || isRequired
									})}>
									{/* <InputNumber
										placeholder={i === 0 && x === 0 ? '请先输入最小订货数量' : '请输入'}
										autoComplete="off"
										disabled={disabled}
										value={theadItem.key === 'salePrice' ? child[theadItem.key] / 100 : child[theadItem.key]}
										max={theadItem.max}
										min={theadItem.min}
										precision={theadItem.precision}
										onChange={(val: any) =>
											onChange('edit', i, {
												...child,
												[theadItem.key]: val,
												[`${theadItem.key}_required`]: !(typeof val === 'number')
											})
										}
									/> */}
									{theadItem.key === 'salePrice' ? child[theadItem.key] / 100 : child[theadItem.key]}
									{isRequired ? (
										<span className="error error-component">
											<div className="field-error ">该项为必填项</div>
										</span>
									) : (
										(!!minErrorText || !!salePriceError) && (
											<span className="error error-component">
												<div className="field-error ">{minErrorText || salePriceError}</div>
											</span>
										)
									)}
								</div>
							</td>
						);
					})}

					<td>
						{!!child.minNum && !!child.salePrice && (
							<span>{`${getNumber(i)}${unit}：${child.salePrice / 100}元/${unit}`}</span>
						)}
					</td>
				</tr>
			))}
		</tbody>
	);
};

interface reserveListTypes {
	minNum?: number;
	salePrice?: number;
}

const GoodsSectionTable: React.FC<GoodsSectionTableProps> = ({
	unit = '箱',
	value = [],
	otherThead = [],
	disabled = false,
	previewText = '预览',
	onChange = () => {}
}) => {
	const inputChange = (type: 'add' | 'edit' | 'remove', index?: number, item?: reserveListTypes) => {
		const _reserveList = [...value];

		switch (type) {
			case 'add':
				_reserveList.push({ minNum: null, salePrice: null });
				break;
			case 'remove':
				if (index !== undefined) {
					_reserveList.splice(index, 1);
				}
				break;
			default:
				if (index !== undefined && !!item) {
					_reserveList[index] = item;
				}
		}

		onChange(_reserveList);
	};

	return (
		<div className="fx__goods-score-table">
			<table className="form-table">
				{getThead(otherThead, previewText)}

				{getTbody(value, otherThead, inputChange, unit, disabled)}
			</table>
		</div>
	);
};
export default React.memo(GoodsSectionTable);
