import React, { FC, ReactElement, useEffect, useState } from 'react';
import ExamineInfo from '@/components/goods/goodDetailInfo';
import { Drawer, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { GoodsStore } from '@/stores';

const DetailPopup: FC<any> = (props): ReactElement => {
	const { goodsId, showFlag } = props;
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [detailInfo, setDetailInfo] = useState({}); // 商品详情

	const formatAddress = (obj: any) => {
		let addressParts = [];

		// Add provinceName if available
		if (obj?.provinceName) {
			addressParts.push(obj.provinceName);
		}

		// Add cityName if available
		if (obj?.cityName) {
			addressParts.push(obj.cityName);
		}

		// Add countyName if available
		if (obj?.countyName) {
			addressParts.push(obj.countyName);
		}

		// Add townName if available and not null
		if (obj?.townName && obj?.townName !== 'null') {
			addressParts.push(obj.townName);
		}

		// Add address
		if (obj?.address) {
			addressParts.push(obj.address);
		}

		// Combine name and mobile
		let nameAndMobile = obj?.name && obj?.mobile ? `(${obj.name}-${obj.mobile})` : '';

		// Combine all parts and return
		return addressParts.join('-') + nameAndMobile;
	};

	//查看详情
	const handleDetail = async (goodsId: any) => {
		const res: any = await GoodsStore.getGoodsDetail({ goodsId: goodsId });
		if (res?.success) {
			const targetInfo = {
				...(res?.data?.basicInfo || {}),
				...(res?.data?.salesInfo || {}),
				purchaseParam: {
					...res?.data?.basicInfo?.purchaseInfo,
					...res?.data?.salesInfo?.purchaseInfo,
					purchasePrice: res?.data?.salesInfo?.purchaseInfo?.purchasePriceMin,
					marketPrice: res?.data?.salesInfo?.purchaseInfo?.marketPriceMin,
					customerAddressName: formatAddress(res?.data?.basicInfo?.purchaseInfo?.customerAddressInfo),
					returnAddressName: formatAddress(res?.data?.basicInfo?.purchaseInfo?.returnAddressInfo)
				},
				onceParam: {
					...res?.data?.basicInfo?.onceInfo,
					...res?.data?.salesInfo?.onceInfo,
					purchasePrice: res?.data?.salesInfo?.onceInfo?.purchasePriceMin,
					marketPrice: res?.data?.salesInfo?.onceInfo?.marketPriceMin,
					customerAddressName: formatAddress(res?.data?.basicInfo?.onceInfo?.customerAddressInfo),
					returnAddressName: formatAddress(res?.data?.basicInfo?.onceInfo?.returnAddressInfo)
				}
			};
			setDetailInfo(targetInfo);
			console.log('接口数据------', targetInfo);
			setDrawerVisible(true);
		} else {
			message.error(res.message);
			return res;
		}
	};

	useEffect(() => {
		console.log('goodsId', goodsId, showFlag);
		if (goodsId) {
			console.log('innnnnn');
			handleDetail(goodsId);
		}
	}, [goodsId, showFlag]);

	return (
		<Drawer
			title="商品详情"
			placement="right"
			closable={false}
			open={drawerVisible}
			destroyOnClose={true}
			width={800}
			onClose={() => setDrawerVisible(false)}
			extra={<CloseOutlined className="drawer-close-icon" onClick={() => setDrawerVisible(false)} />}>
			<ExamineInfo afterInfo={detailInfo} beforeInfo={detailInfo} judge="before" type="detail" />
		</Drawer>
	);
};

export default DetailPopup;
