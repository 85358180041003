import { makeAutoObservable, configure, runInAction, action } from "mobx";
import $http from '@/tools/service';
import { once } from "@/api";
import { formatArea } from "@/tools";

configure({ enforceActions: "observed" });

class StoreFreightStore {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }

  public freightList = [];
  public freightSimpleList = [];
  public freightDetail = {};

  /**
   * 运费模板列表
   * @param params
   * @returns {Promise<*>}
   */
 
  getFreightList = async (params: any) => {
    const res: any = await $http.post(once.POST_FREIGHT_LIST, params);
    if (res.success) {
      let list = res.data.map((item: any, index: number) =>
        Object.assign(item, { key: index })
      );
      this.freightList = list;
      return list;
    }
    return [];
  };

  /**
   * 运费模板简单列表
   * @param params
   * @returns {Promise<*>}
   */
 
  getFreightSimpleList = async (params: any) => {
    const res: any = await $http.post(
      once.GET_FREIGHT_SIMPLE_LIST,
      params
    );
    if (res.success) {
      let list = res.data.map((item: any, index: number) =>
        Object.assign(item, { key: index })
      );
      this.freightSimpleList = list;
      return list;
    }
    return [];
  };

  /**
   * 获取运费模板详情
   * @param params
   * @returns {Promise<*>}
   */
 
  getFreightDetail = async (params: any) => {
    const res: any = await $http.post(once.POST_FREIGHT_DETAIL, params);
    if (res.success) {
      this.freightDetail = res.data;
      return res.data;
    }
  };

  /**
   * 增加运费模板
   * @param params
   * @returns {Promise<*>}
   */
 
  addFreight = async (params: any) => {
    return await $http.post(once.POST_ADD_FREIGHT, params);
  };

  /**
   * 修改运费模板
   * @param params
   * @returns {Promise<*>}
   */
 
  updateFreight = async (params: any) => {
    return await $http.post(once.POST_UPDATE_FREIGHT, params);
  };

  /**
   * 删除运费模板
   * @param params
   * @returns {Promise<*>}
   */
 
  deleteFreight = async (params: any) => {
    return await $http.post(once.POST_DELETE_FREIGHT, params);
  };

  /**
   * 获取运费模板区域列表
   * @param params
   * @returns {Promise<void>}
   */
 
  getFreightArea = async (params: any) => {
    const data: any = await $http.get(once.GET_FREIGHT_AREA, { params });
    if (data?.success) {
      localStorage.setItem(
        "freightArea",
        JSON.stringify(formatArea(data.data))
      );
    }
  };
}

export default new StoreFreightStore();
