/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-04-19 16:30:00
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-20 00:17:38
 */
import { makeAutoObservable, configure, runInAction, action } from "mobx";
// import $http from "tools/$http";
import $http from '@/tools/service';
import { once } from "@/api";

configure({ enforceActions: "observed" });

class StoreLocationStore {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }

  public locationList = [];
  public defaultLocation = {};

  /**
   * 获取地址列表
   * @param params
   * @returns {Promise<*[]|T>}
   */
  
  getLocationList = async (params: any) => {
    const res: any = await $http.post(once.GET_lOCATION_LIST, params);
    if (res?.success) {
      this.locationList = res.data;
      return res.data.map((item: any, index: number) =>
        Object.assign(item, { key: index })
      );
    }
    return [];
  };

  /**
   * 获取地址列表
   * @param params
   * @returns {Promise<*[]|T>}
   */
  
  getDefaultLocation = async (params: any) => {
    const res: any = await $http.post(
      once.POST_GET_DEFAULT_LOCATION,
      params
    );
    if (res.success) {
      this.defaultLocation = res.data;
      return res.data;
    }
  };

  /**
   * 新增地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  
  addLocation = async (params: any) => {
    return await $http.post(once.POST_ADD_lOCATION, params);
  };

  /**
   * 修改地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  
  updateLocation = async (params: any) => {
    return await $http.post(once.POST_UPDATE_lOCATION, params);
  };

  /**
   * 删除地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  
  deleteLocation = async (params: any) => {
    return await $http.post(once.POST_DELETE_lOCATION, params);
  };

  /**
   * 设置默认地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  
  setDefaultLocation = async (params: any) => {
    return await $http.post(once.POST_SET_DEFAULT_LOCATION, params);
  };
}

export default new StoreLocationStore();
