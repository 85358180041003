import React, { useEffect, useState } from 'react';
import { Badge, Drawer, Tabs, TabsProps, message } from 'antd';
import NP from 'number-precision';

import { Icon } from '@/components';
import { MessageCenterPageApi } from '@/api/messageCenter';

import MessageBox from './MessageBox';

import './index.less';

interface NoticeDrawerProps {
	open: boolean;
	setMessageOpen: Function;
	setMessageNum: Function;
	getMessageCountNum: Function;
}
const NoticeDrawer: React.FC<NoticeDrawerProps> = ({ open, setMessageOpen, setMessageNum, getMessageCountNum }) => {
	const [tabType, setTabType] = useState<string>('0');
	const [messageType, setMessageType] = useState<string>('0');
	const [businessNum, setBusinessNum] = useState<number | null>(null);
	const [systemNum, setSystemNum] = useState<number | null>(null);
	const onChange = (key: string) => {
		setMessageType(key);
	};
	useEffect(() => {
		if (open) {
			setMessageType('10');
			getCountNum();
		}
	}, [open]);
	const items: TabsProps['items'] = [
		{
			key: '10',
			label: (
				<Badge size="small" offset={[8, -5]} count={systemNum}>
					系统通知
				</Badge>
			)
		},
		{
			key: '20',
			label: (
				<Badge size="small" offset={[8, -5]} count={businessNum}>
					业务通知
				</Badge>
			)
		}
	];
	const allRead = async () => {
		const success = await MessageCenterPageApi.remindRead({});
		if (success) {
			message.success('操作成功');
			setMessageType('0');
			setTabType('0');
			setTimeout(() => {
				setMessageType('10');
			}, 100);
			getCountNum();
		}
	};
	// 获取数量
	const getCountNum = async () => {
		const param = {
			channelType: 10,
			isRead: -10 // 未读数量
		};
		const res: any = await MessageCenterPageApi.remindCountNum(param);
		setSystemNum(res.data?.systemNum);
		setBusinessNum(res.data?.businessNum);
		setMessageNum(NP.plus(res.data?.systemNum, res.data?.businessNum));
	};
	return (
		<Drawer
			width={544}
			className="message-drawer-root"
			title="消息中心"
			open={open}
			onClose={() => {
				setMessageOpen(false);
				setMessageType('0');
			}}>
			<Tabs className="tab-class" activeKey={messageType} items={items} onChange={onChange} />
			<div className="has-read-box" onClick={allRead}>
				{' '}
				<Icon type="icon-yidu" /> 全部标记为已读
			</div>
			<div className="message-tab-box">
				<div
					className={tabType === '0' ? 'message-tab message-tab-active' : 'message-tab'}
					onClick={() => {
						setTabType('0');
					}}>
					全部消息
				</div>
				<div
					className={tabType === '-10' ? 'message-tab message-tab-active' : 'message-tab'}
					onClick={() => {
						setTabType('-10');
					}}>
					仅展示未读
				</div>
				<div
					className={tabType === '10' ? 'message-tab message-tab-active' : 'message-tab'}
					onClick={() => {
						setTabType('10');
					}}>
					仅展示已读
				</div>
			</div>
			{messageType === '10' && (
				<MessageBox
					messageType="10"
					tabType={tabType}
					setMessageOpen={() => {
						setMessageOpen(false);
						getMessageCountNum();
					}}
				/>
			)}
			{messageType === '20' && (
				<MessageBox
					messageType="20"
					tabType={tabType}
					setMessageOpen={() => {
						setMessageOpen(false);
						getMessageCountNum();
					}}
				/>
			)}
		</Drawer>
	);
};
export default React.memo(NoticeDrawer);
