import React from 'react';
import type { RoutesType } from '@fle-ui/plus-router';
import Layout from '@/layout';
import Webview from '@/pages/webview';
import inquiryManage from './inquiryManage';

const _router: RoutesType = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '',
				// redirect: '/wares/list'
					redirect: '/home'
			},
			{
        path: '/webview',
        children: [
          {
            path: '*',
            element: <Webview />,
          },
        ],
      },
			{
				path: '/home',
				component: () => import('@/pages/home')
			},
			//商品管理
			{
				//商品库
				path: '/wares/list',
				component: () => import('@/pages/goods/goodsStock')
			},
			{
				//审核变更申请
				path: '/wares/examine',
				component: () => import('@/pages/goods/goodsExamine')
			},
			{
				//运费模板
				path: '/wares/freight-template',
				component: () => import('@/pages/goods/freightTemplate')
			},
			{
				//新建运费模板
				path: '/wares/freight/add-template',
				component: () => import('@/pages/goods/freightTemplate/addTemplate')
			},
			{
				//编辑运费模板
				path: '/wares/freight/edit-template',
				component: () => import('@/pages/goods/freightTemplate/addTemplate')
			},
			{
				//发货地址
				path: '/wares/send-address',
				component: () => import('@/pages/goods/sendAddress')
			},
			{
				// 编码规则
				path: '/wares/coding',
				component: () => import('@/pages/goods/goodsCoding')
			},
			{
				// 企业信息详情
				path: '/wares/companyInfo',
				component: () => import('@/pages/goods/companyInfo/Detail')
			},
			{
				// 企业信息编辑
				path: '/wares/companyInfo/edit',
				component: () => import('@/pages/goods/companyInfo/Add')
			},
			// 销售
			{
				//销售订单
				path: '/sale/saleOrder',
				component: () => import('@/pages/sale/saleOrder')
			},
			{
				//销售订单详情
				path: '/sale/saleOrder/detail/:id',
				component: () => import('@/pages/sale/saleOrder/Detail')
			},
			// 售后
			{
				//售后订单
				path: '/sale/afterSale',
				component: () => import('@/pages/sale/afterSale')
			},
			{
				path: '/sale/proxy',
				component: () => import('@/pages/sale/proxyRequest')
			},
			// 代发
			{
				path: '/sale/proxy/detail/:id',
				component: () => import('@/pages/sale/proxyRequest/Detail')
			},
			{
				path: '/module/*',
				component: () => import('@/pages/microApp')
			},
			{
				path: '/download',
				component: () => import('@/pages/download')
			},
			{
				path: '/wares/orderDispatch',
				component: () => import('@/pages/financeManagement/orderDispatch')
			},
			{
				//代发账单
				path: '/wares/billForwarding',
				component: () => import('@/pages/financeManagement/billForwarding')
			},
			
			//询价管理
			...inquiryManage
		]
	}
];

export default _router;
