/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-22 13:57:03
 */
const once: { [key: string]: string } = {
	//商品库部分
	POST_BASIC_GOODSLIST: '/bestgift/supplier/goods/goods/search/getSupplierGoodsList',
	POST_CATEGORYID_LIST: '/bestgift/supplier/goods/category/getTree', //分类树列表
	POST_GOODS_RECORD_LIST: '/bestgift/supplier/goods/goods/search/goods-reject-record-list', //驳回历史记录

	POST_OFFSELF: '/bestgift/goods/goods/offShelf', //商品下架  ????
	POST_ONDELF: '/bestgift/goods/goods/onlineShelf', //商品上架 ????
	POST_BATCH_DELETE_GOODS: '/bestgift/supplier/goods/goods/deleteSelfSoldOutGoods', //已发布列表删除
	POST_BATCH_DELETE_REJECT_GOODS: '/bestgift/supplier/goods/goods/deleteSelfGoods', //已发布列表删除

	POST_GOODS_REMOVE: '/bestgift/goods/goods/removeSelfGoods', //移除商品 ????
	POST_FLAG_LIST: '/bestgift/goods/flag/getList', //获取品类列表
	POST_MULTIPLE_ON: '/bestgift/goods/goods/onlineShelfBatch', //批量上架 ??
	POST_MULTIPLE_OFF: '/bestgift/goods/goods/offShelfBatch', //批量下架 ????
	POST_CHECK_SHELF: '/bestgift/goods/goods/checkShelf', //编辑价格 ????
	POST_TEMPLATE: '/bestgift/basic/memberFreightTemplate/templates-simple', //运费模板列表

	// POST_BRAND_LIST: "/bestgift/supplier/goods/brand/getListPage", //品牌列表
	POST_BRAND_LIST: '/bestgift/goods/brand/v2/supplier/pageList', //品牌列表

	POST_DELETE_GOODS: '/bestgift/supplier/goods/goods/deleteSelfGoods', //草稿箱删除商品
	POST_ADDRESS_LIST: '/fleshopy/person/OwnSupplier-adress/addresssByOwnSupplier', //发货地址列表

	POST_CONFING_GOODS: '/bestgift/supplier/goods/goods/updateShelfGoodsInstall', //批量修改草稿箱商品

	POST_PICK_GOODS_LIST: '/bestgift/goods/goods/search/getPickGoodsList', //挑选商品列表 ???
	POST_REJECT_GOODS: '/bestgift/goods/goods/rejectShelf', //商品驳回 ?????
	POST_GOODS_CHANGE_LIST: '/bestgift/supplier/self/goods/change/getListByPage', //商品变更列表
	POST_CHANGE_GOODS: '/bestgift/supplier/self/goods/change/apply',
	POST_USER_LIST: '/bestgift/supplier/self/goods/change/getApproveUserList', //操作人列表

	POST_EXPORT: '/bestgift/supplier/goods/goods/exportSelfGoods', //导出文件
	POST_IMPORT: '/bestgift/supplier/goods/goods/importSelfGoods', //上传文件

	POST_EXAMINE_REJECT: '/bestgift/self/goods/change/reject', //审核拒绝？？？
	POST_EXAMINE_PASS: '/bestgift/self/goods/change/pass', //审核通过 ？？？？

	POST_CHANFE_INFO: '/bestgift/supplier/self/goods/change/info', //变更详情

	POST_EDIT_COLUMN: '/bestgift/column-key/modifyColumnKey', //修改列设置
	POST_GET_COLUMN: '/bestgift/column-key/getColumnKey', //获取列设置

	GET_REST_COLUMN: '/bestgift/column-key/deleteColumnKey', //重置列设置

	// 运费模板部分
	POST_FREIGHT_LIST: '/bestgift/basic/memberFreightTemplate/templates', //运费模板列表
	GET_FREIGHT_SIMPLE_LIST: '/bestgift/basic/memberFreightTemplate/templates-simple', //运费模板简单列表
	POST_FREIGHT_DETAIL: '/bestgift/basic/memberFreightTemplate/get', //获取运费模板详情
	POST_ADD_FREIGHT: '/bestgift/basic/memberFreightTemplate/save', //增加运费模板
	POST_UPDATE_FREIGHT: '/bestgift/basic/memberFreightTemplate/update', //修改运费模板
	POST_DELETE_FREIGHT: '/bestgift/basic/memberFreightTemplate/delete', //删除运费模板
	GET_FREIGHT_AREA: '/bestgift/basic/dict/dictionaries', //获取运费模板区域

	//地址管理部分
	// GET_lOCATION_LIST: '/merchant/person/customerAddress/addressList', //获取地址列表
	GET_lOCATION_LIST: '/fleshopy/person/OwnSupplier-adress/addresssByOwnSupplier', //获取地址列表
	POST_ADD_lOCATION: '/fleshopy/person/OwnSupplier-adress/createByOwnSupplier', //新增地址
	POST_UPDATE_lOCATION: '/fleshopy/person/OwnSupplier-adress/updateByOwnSupplier', //修改地址
	POST_DELETE_lOCATION: '/fleshopy/person/OwnSupplier-adress/deleteByOwnSupplier', //删除地址
	//所属分类列表

	GET_SUBSUPPLIERLIST: '/bestgift/supplier/goodsCategory/tree',
	//获取备用字段
	GET_EXTLIST: '/elebuys/extend/field/config/query',
	getGoodsDetail: '/bestgift/goods/goods/getGoodsDetail'
};

export default once;
