import type { FleAppEnv, BaseURLMap } from '@/types/const/serviceEnv';

export const fleAppEnv: FleAppEnv = process.env.__fle_env__ || "prod";
export const TokenKey = 'supplier_token';
export const baseURLMap: BaseURLMap = {
  'java': {
    dev: 'https://dev-gateway.fxqifu.net/pangu',
    qa: 'https://qa-gateway.fxqifu.net/pangu',
    prod: 'https://gateway.fxqifu.net/pangu'
  },
  // 3.0供应商运营后台
  supplier: {
    dev: 'https://qa-supplier.carpunion.com',
    qa: 'https://qa-supplier.carpunion.com',
    prod: 'https://supplier.carpunion.com',
  },
  oldSupplier: {
    dev: 'https://supplier.koudaiqifu.cn',
    qa: 'https://supplier.koudaiqifu.cn',
    prod: 'https://supplier.fxqifu.com',
  },
}

// const LOGIN_HOST: Map<envType, string> = new Map([
//   ["dev", "http://dev-fle.s.peliten.com"],
//   ["qa", "https://qa-fle.s.peliten.com"],
//   ["prod", "https://fle.s.peliten.com"],
// ]);
// const LOGIN_ADMIN_HOST = LOGIN_HOST.get(fleAppEnv);
export const baseServiceType: string = 'java';

export const baseURL: string = baseURLMap[baseServiceType][fleAppEnv] || '';
export const getApplicationUrl = (application: any, router = '') => {
  return `${baseURLMap[application][fleAppEnv]}${router}`;
};