import service from '@/tools/service';

type apiFetch = {
	url: string;
	api: string;
	method: 'post' | 'get';
};

const apiList: apiFetch[] = [
  {
    url: '/bestgift/ownSupplier/archiveInfo', // 获取
    api: 'getSupplierInfo',
    method: 'get',
  },
  {
    url: '/bestgift/ownSupplier/changeSubmit',
    api: 'applyModifyInfo',
    method: 'post'
  },
  {
    url: '/bestgift/ownSupplier/changePwd',
    api: 'modifyPwd',
    method: 'post'
  },
  {
    url: '/bestgift/ownSupplier/operate/state',
    api: 'getSupplierState',
    method: 'post'
  },
  {
    url: '/bestgift/ownSupplier/revoke',
    api: 'revokeSupplier',
    method: 'post'
  },
  {
    url: '/bestgift/ownSupplier/viewRejectReason',
    api: 'getReason',
    method: 'post'
  }
];

type AdmittancePageApiType = {
	[key: string]: any;
};

const AdmittancePageApi: AdmittancePageApiType = {};

apiList.forEach((item: apiFetch) => {
	AdmittancePageApi[item.api] = async (params: any) => {
		return service[item.method](`${item.url}`, item.method === 'get' ? { params } : params);
	};
});

export { AdmittancePageApi };
