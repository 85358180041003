const common: Map<string, string> = new Map([
	['get_address', '/basic/area/areas'], //获取地址
	['get_member_info', '/bestgift/ownSupplier/supplierInfo'], // 获取用户信息
	['login_out', '/elebuys/right/login/logout'], // 退出登录
	['get_query_info', '/elebuys/right/login-page/query'], // 获取自定义门户配置
	['get_purchasePrincipal', '/bestgift/ownSupplier/purchasePrincipal'], //获取采购人列表
	['get_accountInfo', '/bestgift/ownSupplier/account/info/by-account'], //获取采购人列表
	['get_accountLogin', '/bestgift/ownSupplier/account/login-token/get'], // 获取免登录
]);
export default common;
