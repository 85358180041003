import React from 'react';
// import { showMoney } from '@/tools/common';
import { Image } from 'antd';

import './index.less';

interface otherTheadProps {
	key: string;
	name: string;
	type?: 'text' | 'image';
	mergeRowSpan?: boolean;
}

interface GoodsSkuTableProps {
	thead?: otherTheadProps[];
	otherThead?: otherTheadProps[];
	boxNumber?: number;
	measureUnit?: number;
	skuList: any[];
	skuPriceList?: any[];
}

interface TheadProps {
	propertyNames: string[];
	thead?: otherTheadProps[];
	otherThead?: otherTheadProps[];
}

type moneyType = number | string;

interface ShowMoneyType {
	(data: moneyType): string;
}
const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);
// const showMoney: ShowMoneyType = (data) => (+data / 10).toFixed(2);

// 获取表头
const getThead = ({ propertyNames = [], thead = [], otherThead = [] }: TheadProps) => {
	const _thead = [...thead, ...otherThead];

	return (
		<thead>
			<tr>
				{propertyNames?.map((item: any, index: number) => (
					<th key={index}>
						<span>{item}</span>
					</th>
				))}
				{_thead?.map((item: otherTheadProps) => (
					<th key={item.key}>
						<span>{item.name}</span>
					</th>
				))}
			</tr>
		</thead>
	);
};

interface TbodyProps extends Omit<GoodsSkuTableProps, 'attrList'> {
	singleAttributeNum: number;
}

// 获取表内容
const getTbody = ({ thead, otherThead, skuPriceList, skuList, singleAttributeNum }: TbodyProps) => {
	return (
		<tbody>
			{skuList?.map((item: any, i: number) => (
				<tr key={item.skuPropertyOrder}>
					{item.cartesianSkuItem?.map((name: string, x: number) => {
						if (!(i % singleAttributeNum) && !x) {
							return (
								<td rowSpan={singleAttributeNum} key={x}>
									<span className="no-wrapper">{name}</span>
								</td>
							);
						} else if (!!x) {
							return (
								<td rowSpan={1} key={x}>
									<span className="no-wrapper">{name}</span>
								</td>
							);
						} else {
							return null;
						}
					})}
					{thead?.map((theadItem: otherTheadProps) => {
						const _Item = skuPriceList?.find((sku) => item.skuPropertyOrder === sku.skuPropertyOrder);
						return <td key={theadItem.key}>{showMoney(_Item[theadItem.key])}</td>;
					})}
					{otherThead?.map((theadItem: otherTheadProps) => {
						if (!!theadItem.mergeRowSpan) {
							return !(i % singleAttributeNum) ? (
								theadItem.type === 'image' ? (
									<td key={theadItem.key} rowSpan={singleAttributeNum}>
										<Image src={item[theadItem.key]} width={60} style={{ padding: 5 }} />
									</td>
								) : (
									<td key={theadItem.key} rowSpan={singleAttributeNum}>
										{item[theadItem.key]}
									</td>
								)
							) : null;
						} else {
							return theadItem.type === 'image' ? (
								<td key={theadItem.key}>
									<Image src={item[theadItem.key]} width={60} style={{ padding: 5 }} />
								</td>
							) : (
								<td key={theadItem.key}>{item[theadItem.key]}</td>
							);
						}
					})}
				</tr>
			))}
		</tbody>
	);
};

const GoodsSkuTable: React.FC<GoodsSkuTableProps> = ({
	thead = [],
	otherThead = [],
	boxNumber = 1,
	skuPriceList = [],
	skuList = []
}) => {
	const _skuList = skuList.map((item: any) => {
		const propertyNames: string[] = [],
			cartesianSkuItem: string[] = [];

		if (item?.specList) {
			item.specList = Array.isArray(item?.specList) ? item?.specList : JSON.parse(item?.specList || '[]');
			try {
				(Array.isArray(item?.specList) ? item?.specList : JSON.parse(item?.specList || '[]')).forEach((spec: any) => {
					propertyNames.push(spec?.name);
					cartesianSkuItem.push(spec?.value);
				});
			} catch (error) {
				console.log(error);
			}
		}
		return {
			...item,
			marketPrice: (item.marketPrice / 100).toFixed(2),
			purchasePrice: (item.purchasePrice / 100).toFixed(2),
			salePrice: (item.salePrice / 100).toFixed(2),
			propertyNames,
			cartesianSkuItem
		};
	});

	let _specList: any = [];
	_skuList?.forEach((item: any) => {
		_specList = _specList.concat(item.specList);
	});

	function transformArray(arr: any[]) {
		const result: any = [];
		const propertyNames = new Set();

		arr.forEach((obj) => propertyNames.add(obj.name));

		propertyNames.forEach((propertyName) => {
			const attributes: any = [];
			const uniqueValues = new Set(); // 使用 Set 对 value 去重

			arr.forEach((obj) => {
				if (obj.name === propertyName && !uniqueValues.has(obj.value)) {
					uniqueValues.add(obj.value);
					attributes.push({ attribute: obj.value });
				}
			});

			result.push({ propertyName, attributes });
		});

		return result;
	}

	const _attrList = transformArray(_specList);
	const propertyNames: string[] = _attrList?.length ? _attrList.map((item: any) => item.propertyName) : [];
	const singleAttributeNum =
		propertyNames.length > 1
			? _attrList[_attrList.length - 1].attributes.filter((item?: { attribute: string }) => !!item && !!item.attribute)
					.length
			: 1;

	return (
		<div className="fx__goods-sku-table">
			<table className="form-table">
				{getThead({
					propertyNames,
					thead,
					otherThead
				})}

				{getTbody({
					thead,
					otherThead,
					skuPriceList,
					singleAttributeNum,
					boxNumber,
					skuList: _skuList
				})}
			</table>
		</div>
	);
};

export default GoodsSkuTable;
