import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { CommonStore } from "@/stores";
import Cookies from "js-cookie";
import { Modal, Form, Input, message, Dropdown, Badge, Button, notification } from "antd";
import {
  LockOutlined,
  LogoutOutlined,
  SettingOutlined,
  DownloadOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { AdmittancePageApi } from "@/api/supplierAdmittanceSetting";
import md5 from "js-md5";
import { ProLayout, type ProSettings } from "@ant-design/pro-components";
import MenuConfig from "@/const/menu.config";
import SupplierConfig from "@/const/supplier.config";
import theme from "@fle-ui/plus-theme";
import { useForm } from "antd/es/form/Form";
import { MessageCenterPageApi } from "@/api/messageCenter";
import NP from "number-precision";
import { useFavicon, useTitle } from "ahooks";
import NoticeDrawer from "./NoticeDrawer";
import HeaderMenu from "./HeadMenu";
import { getQuery } from "@/tools";
import { useNavigate } from "react-router-dom";
import {  getApplicationUrl } from '@/const/serviceEnv';
import axios from 'axios';  
const { proTheme } = theme;

const Router: React.FC = (): React.ReactElement => {
  const settings: ProSettings = {
    fixSiderbar: true,
    layout: "mix",
    splitMenus: false,
  };
  const query =  getQuery()
  const navigate = useNavigate();
  const pathname = window.location.pathname
  const [selectMenuType, setSelectMenuType] = useState<any>(MenuConfig);

  const [menu, setMenu] = useState<any>([]);
  const silderMenu: any = [
    {
      routes: MenuConfig,
      key: "saasSupplier",
    },
    {
      routes: SupplierConfig,
      key: "supplier",
    },
  ];
  const location = useLocation();
  const oldSupplier = getApplicationUrl('oldSupplier');
  const {
    getMemberInfo,
    getQueryInfo,
    memberInfo,
    queryInfo,
    isLoadingMemberInfo,
    getSupplierLoginInfo
  } = CommonStore;
  const [messageNum, setMessageNum] = useState<number | null>(null);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageCenterOpen, setMessageCenterOpen] = useState<boolean>(false);
  const [form] = useForm();
  form.setFieldsValue({
    companyAccount: memberInfo?.account,
  });

  useFavicon(queryInfo?.backendLogo || "");
  useTitle(queryInfo?.portalName || "");

  useEffect(() => {
    const fetchInit = async () =>{
      if(query?.ticket && pathname === '/home/home'){
        const res = await getSupplierLoginInfo({loginTicket:decodeURIComponent(query?.ticket)})
          if(res?.success){
            Cookies.set('supplier_token',res?.data?.token)
            getMemberInfo();
            getQueryInfo();
            getMessageCountNum();
            navigate('/home', { replace: true });
          }else{
            handleExit()
          }      
      }else{
        getMemberInfo();
        getQueryInfo();
        getMessageCountNum();
      }
    }
    fetchInit()
  }, []);

  useEffect(() => {
    if (!memberInfo?.account) return;
    CommonStore.getAccountInfo({account: memberInfo?.account}).then((res: any) => {
      console.log(res.data?.supplyPlatform,'res.data?.supplyPlatform ');
      
      if (res?.success) {
        const supplyPlatform = res.data?.supplyPlatform || 1;
        if (supplyPlatform === 3) {
          setMenu([
            {  name: "集采供应",  key: "saasSupplier"},
            {  name: "代发供应",  key: "supplier"},
          ]);
        } else if (supplyPlatform === 2) {
          setMenu([
            {  name: "",  key: "supplier"},
          ]);
          changeMenu('supplier')
        }else if(supplyPlatform == -10){
          notification.error({
            message: "提示",
            description: '该账号已过期,请开通',
          });
          handleExit()
        } else {
          setMenu([
            {  name: "",  key: "saasSupplier"},
          ]);
        }
      }
    }).catch(() => {
      setMenu([
        {  name: "",  key: "saasSupplier"},
      ]);
    })
  }, [memberInfo?.account]);


  

  // 获取消息数量
  const getMessageCountNum = async () => {
    const param = {
      channelType: 10,
      isRead: -10, // 未读数量
    };
    const res: any = await MessageCenterPageApi.remindCountNum(param);
    setMessageNum(NP.plus(res.data?.systemNum, res.data?.businessNum));
  };
  const handleFastApply = (type?: string) => {
    if (type === "download") {
      window.location.href = "/home/download";
    }
  };

  const handleExit = async () => {
    axios.get(`${oldSupplier}/Welcome/logout`, { params:{pangu_token:Cookies.get('supplier_token')} })  
      .then(response => {  
        console.log('Response data:', response.data);  
      })  
      .catch(error => {  
        console.error('Error:', error);  
      });  
    const res: any = await CommonStore.loginOut();
    if (res?.success) {
      const loginLink = Cookies.get("loginLink")?.replace(/^https?:\/\//i, "");
      window.location.href = loginLink
        ? `https://${loginLink}`
        : `/account/login`;
    }
  };
  // 做切换菜单对菜单显示隐藏做更改
  const changeMenu = (data: string) => {
    console.log(data, "data");
    console.log(silderMenu, "silderMenu");
    const arr = silderMenu.filter((item: any) => {
      return item.key == data;
    });
    setSelectMenuType(arr[0].routes);
  };
  const onFinish = async () => {
    const res = await form?.validateFields();
    await AdmittancePageApi.modifyPwd({
      oldPassword: (md5 as any)(res.oldPassword),
      newPassword: (md5 as any)(res.newPassword),
      confirmPassword: (md5 as any)(res.confirmPassword),
    });
    if (res) {
      // 提交成功
      message.success("密码修改成功!");
      setMessageOpen(false);
      form?.resetFields();
    }
  };

  return (
    <ProLayout
      layout="side"
      loading={isLoadingMemberInfo}
      title={!!queryInfo?.portalName ? queryInfo?.portalName : "供应商管理后台"}
      logo={queryInfo?.backendLogo}
      siderWidth={172}
      route={{ path: "/", routes: selectMenuType }}
      location={{
        pathname: location.pathname.replace(/^\/home/, ""),
      }}
      avatarProps={{
        src: "https://oss.elebuys.com/tmpdir/202308171743390002128752.png",
        crossOrigin: "anonymous",
        title: (
          <span style={{ color: "#000" }}>{memberInfo?.supplierName}</span>
        ),
        style: {
          backgroundColor: "rgb(187, 207, 255)",
        },
        render(props, dom) {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "cg-pwd",
                    icon: <SettingOutlined />,
                    label: "修改密码",
                    onClick: () => {
                      setMessageOpen(true);
                    },
                  },
                  {
                    key: "logout",
                    icon: <LogoutOutlined />,
                    label: "退出登录",
                    onClick: handleExit,
                  },
                ],
              }}
            >
              {dom}
            </Dropdown>
          );
        },
      }}
      actionsRender={() => {
        return [
          <Button
            type="text"
            size="small"
            style={{ height: 44 }}
            onClick={() => setMessageCenterOpen(true)}
          >
            <MessageOutlined />
            <Badge size="small" count={messageNum}>
              <div
                style={{
                  width: 59,
                  height: 33,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {"消息中心"}
              </div>
            </Badge>
          </Button>,
          <Button
            type="text"
            size="small"
            style={{ height: 44 }}
            onClick={() => handleFastApply("download")}
            key="cg-pwd"
          >
            <DownloadOutlined />
            下载中心
          </Button>,
        ];
      }}
      headerContentRender={() => (
        <HeaderMenu menuData={menu} changeMenu={changeMenu} />
      )}
      menuItemRender={(item, dom) => <Link to={item.path || "/"}>{dom}</Link>}
      pageTitleRender={false}
      token={proTheme}
      {...settings}
    >
      <Outlet />
      <Modal
        title={"修改密码"}
        okText={"提交"}
        onCancel={() => setMessageOpen(false)}
        open={messageOpen}
        onOk={onFinish}
      >
        <Form
          form={form}
          name="modify_password"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item name="companyAccount" label="公司账号">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="oldPassword"
            label="旧密码"
            rules={[{ required: true, message: "请输入旧密码" }]}
            // hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="请输入旧密码"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="新密码"
            rules={[
              { required: true, message: "请输入新密码" },
              {
                pattern:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{8,20}$/,
                message: "必须为8-20位且包含字母、数字、特殊符号",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="newpassword"
              placeholder="请输入新密码"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="确认新密码"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "请确认新密码",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("两次输入的密码不一致！"));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="请再次输入新密码以确认"
            />
          </Form.Item>
        </Form>
      </Modal>
      <NoticeDrawer
        open={messageCenterOpen}
        setMessageOpen={setMessageCenterOpen}
        setMessageNum={setMessageNum}
        getMessageCountNum={getMessageCountNum}
      />
    </ProLayout>
  );
};

export default observer(Router);
