/*
 * @Author: your name
 * @Date: 2021-09-19 10:31:03
 * @LastEditTime: 2021-10-13 19:20:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /fx-peliten-basic-module/src/stores/supplier.ts
 */
import { makeAutoObservable } from 'mobx';
import $http from '@/tools/service';
import { downLoad } from '@/api';

import type { StateProps } from '@/types/common/store';

class downLoadStore {

  [key: string]: any;

  constructor() {
    makeAutoObservable(this);
  }
  //任务分页列表
  pageList = async (params: any = {}) => {
    try {
      const { data: { list, total, } } = await $http.post(downLoad.DOWNLOAD_PAGE_LIST, params);
      return new Promise((resolve) => {
        resolve({
          data: list,
          total,
        })
      })
    } catch (ex) {
      console.warn(ex);
    }
  }
  //任务数量统计
  taskCount = async (params: any = {}) => {
    try {
      const data = await $http.post(downLoad.DOWNLOAD_TASK_COUNT, params);
    } catch (ex) {
      console.warn(ex);
    }
  }
  //更新文件为已下载状态
  updateDownloadStatus = async (params: any = {}) => {
    try {
      const data = await $http.post(downLoad.DOWNLOAD_UPDATE, params);
    } catch (ex) {
      console.warn(ex);
    }
  }
}

export default new downLoadStore();