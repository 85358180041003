import React, { useEffect, useMemo, useState } from "react";
import Iframe from "react-iframe";
import Cookies from "js-cookie";
import { pathToRegexp, match, compile } from "path-to-regexp";
import { TokenKey, getApplicationUrl } from "@/const/serviceEnv";
import { useLocation } from "react-router-dom";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import "./index.less";
interface WebviewProps {
  url: string;
  needLGJtoken?: boolean;
  isSupplier?: boolean;
  width?: string;
  height?: string;
  needCenter?: boolean;
  parseQueryToMatch?: boolean;
}

const LiguanjiaSupplier = getApplicationUrl("supplier");

interface WebviewType {
  name: string;
  path: string;
  props: WebviewProps;
}

const webviewList: WebviewType[] = [
  {
    name: "商品库",
    path: "/webview/supplier/publishList",
    props: {
      url: `${LiguanjiaSupplier}/product_library/publish_list`,
    },
  },
  {
    name: "仓库中",
    path: "/webview/supplier/warehouseList",
    props: {
      url: `${LiguanjiaSupplier}/product_library/warehouse_list`,
    },
  },
  {
    name: "批量导入商品",
    path: "/webview/products/adminSupplierBatchImport",
    props: {
      url: `${LiguanjiaSupplier}/v2/product/products/adminSupplierBatchImport`,
    },
  },
  {
    name: "录入商品信息",
    path: "/webview/product/add",
    props: {
      url: `${LiguanjiaSupplier}/product/add`,
    },
  },
  {
    name: "录入商品信息",
    path: "/webview/product/edit/:id",
    props: {
      url: `${LiguanjiaSupplier}/product/edit/:id`,
    },
  },
  {
    name: "信息变更申请记录",
    path: "/webview/productChange/examineList",
    props: {
      url: `${LiguanjiaSupplier}/product_change/examine_list`,
    },
  },
  {
    name: "码池管理",
    path: "/webview/CodePool/index",
    props: {
      url: `${LiguanjiaSupplier}/Code_pool/index`,
    },
  },
  {
    name: "门店管理",
    path: "/webview/SpShop/index",
    props: {
      url: `${LiguanjiaSupplier}/Sp_shop/index`,
    },
  },
  {
    name: "核销管理",
    path: "/webview/WriteOff/pc",
    props: {
      url: `${LiguanjiaSupplier}/Write_off/pc`,
    },
  },
  {
    name: "区域管理",
    path: "/webview/supplier/supplierDistributionArea",
    props: {
      url: `${LiguanjiaSupplier}/supplier/supplierDistributionArea`,
    },
  },
  {
    name: "商品统一说明",
    path: "/webview/product/supplierProducText",
    props: {
      url: `${LiguanjiaSupplier}/product/supplier_product_ext`,
    },
  },
  {
    name: "订单管理",
    path: "/webview/esOrder/index",
    props: {
      url: `${LiguanjiaSupplier}/esOrder/index`,
    },
  },
  {
    name: "售后订单",
    path: "/webview/order/refundOrderList",
    props: {
      url: `${LiguanjiaSupplier}/order/refund_order_list`,
    },
  },
  {
    name: "对账与结算",
    path: "/webview/capital/accountListV2",
    props: {
      url: `${LiguanjiaSupplier}/capital/accountListV2`,
    },
  },
  {
    name: "退货地址设置",
    path: "/webview/supplier/wlSetting",
    props: {
      url: `${LiguanjiaSupplier}/supplier/wl_setting`,
    },
  },
  {
    name: "信息变更已通过",
    path: "/webview/productChange/passList",
    props: {
      url: `${LiguanjiaSupplier}/product_change/pass_list`,
    },
  },
  {
    name: "信息变更未通过",
    path: "/webview/productChange/failList",
    props: {
      url: `${LiguanjiaSupplier}/product_change/fail_list`,
    },
  },
  {
    name: "码池管理详情",
    path: "/webview/CodePool/info",
    props: {
      url: `${LiguanjiaSupplier}/Code_pool/info`,
    },
  },
  {
    name: "新增门店",
    path: "/webview/SpShop/info",
    props: {
      url: `${LiguanjiaSupplier}/Sp_shop/info`,
    },
  },
  {
    name: "批量导入门店",
    path: "/webview/SpShop/importView",
    props: {
      url: `${LiguanjiaSupplier}/Sp_shop/importView`,
    },
  },
  {
    name: "批量导出门店",
    path: "/webview/SpShop/exportShop",
    props: {
      url: `${LiguanjiaSupplier}/Sp_shop/exportShop`,
    },
  },
  {
    name: "下载导入结果",
    path: "/webview/SpShop/downloadImportFail",
    props: {
      url: `${LiguanjiaSupplier}/Sp_shop/downloadImportFail`,
    },
  },
  {
    name: "下载模板",
    path: "/webview/SpShop/importModel",
    props: {
      url: `${LiguanjiaSupplier}/Sp_shop/importModel`,
    },
  },
  {
    name: "手机核销",
    path: "/webview/writeOff/phone",
    props: {
      url: `${LiguanjiaSupplier}/write_off/phone`,
    },
  },
  {
    name: "添加核销员",
    path: "/webview/writeOff/addAccount",
    props: {
      url: `${LiguanjiaSupplier}/write_off/addAccount`,
    },
  },
  {
    name: "核销记录",
    path: "/webview/writeOff/record",
    props: {
      url: `${LiguanjiaSupplier}/write_off/record`,
    },
  },
  {
    name: "订单详情",
    path: "/webview/order/orderDetail/:id",
    props: {
      url: `${LiguanjiaSupplier}/order/orderDetail/:id`,
    },
  },
  {
    name: "售后详情",
    path: "/webview/sale/view/:id",
    props: {
      url: `${LiguanjiaSupplier}/sale/view/:id`,
    },
  },
  {
    name: "导出记录",
    path: "/webview/order/orderExportList",
    props: {
      url: `${LiguanjiaSupplier}/order/orderExportList`,
    },
  },
  {
    name: "导出记录",
    path: "/webview/welcome/index",
    props: {
      url: `${LiguanjiaSupplier}/welcome/index`,
    },
  },
];

const Webview: React.FC = () => {
  const [targetUrl, setTargetUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const { url, width, height, needCenter } = useMemo(() => {
    try {
      const _found = webviewList.find((item) =>
        pathToRegexp(item.path).test(location.pathname)
      );
      // 防止引用变量props.url影响路由拼接值
      const found = _found ? JSON.parse(JSON.stringify(_found)) : _found;
      if (!found) {
        navigate("/404", { replace: true });
      } else {
        const matcher = match(found.path, { decode: decodeURIComponent });
        const matchRes = matcher(location.pathname as string);
        if (matchRes && matchRes.params) {
          const urlArr = found.props.url.split("://");
          const toPath = compile(urlArr[1], { encode: encodeURIComponent });
          const path = toPath(matchRes.params);
          found.props.url = `${urlArr[0]}://${path}`;
        }
        found.props.url = `${found.props.url}${location.search}`;
      }
      return found?.props || { url: "" };
    } catch (error) {
      return { url: "" };
    }
  }, [location]);

  const handleLoad = () => {
    setLoading(false);
  };
  const appendTokenToURL = (url: string) => {
    const token = Cookies.get(TokenKey);
    const queryParams = [`micro=1`, `pangu_token=${token}`];

    const queryString = queryParams.join("&");
    const newUrl = url.includes("?")
      ? `${url}&${queryString}`
      : `${url}?${queryString}`;
    console.log(newUrl, "newUrl");

    setTargetUrl(newUrl);
  };
  const handleExit = async () => {
    const loginLink = Cookies.get("loginLink")?.replace(/^https?:\/\//i, "");
    window.location.href = loginLink
      ? `https://${loginLink}`
      : `/account/login`;
  };
  useEffect(() => {
    console.log(url, "url");

    const handlePostMessage = (event: any) => {
      if (event?.data?.type === "link" && event?.data?.data) {
        navigate(event?.data?.data);
      }
      if (event?.data?.type === "microLogin") {
        // navigate('/home', { replace: true });
        handleExit();
      }
    };

    if (url) {
      window.addEventListener("message", handlePostMessage, false);
      appendTokenToURL(url);
    }
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [url]);

  return (
    <>
      {/* {loading &&  <div className="fx-webview-loading"><Spin size="large" /></div>} */}
      <div
        // style={
        //   needCenter
        //     ? { display: 'flex', justifyContent: 'center', height: '100%' }
        //     : { height: '100%' }
        // }
        style={{ display: "flex", justifyContent: "center", height: "100vh" }}
      >
        <Iframe
          key={targetUrl}
          url={targetUrl}
          width={width || "100%"}
          height={height || "100%"}
          frameBorder={0}
          onLoad={handleLoad}
        />
      </div>
    </>
  );
};

export default Webview;
