import service from '@/tools/service';

type apiFetch = {
	url: string;
	api: string;
	method: 'post' | 'get';
};

const baseUrl = '';

const apiList: apiFetch[] = [
	{
		url: '/bestgift/supplier/remind/countNum', // 统计消息数量
		api: 'remindCountNum',
		method: 'post'
	},

	{
		url: '/bestgift/supplier/remind/getListByPage', // 获取消息列表
		api: 'remindGetListByPage',
		method: 'post'
	},
	{
		url: '/bestgift/supplier/remind/read', // 消息设为已读
		api: 'remindRead',
		method: 'post'
	},
	{
		url: '/antiApprove', // 反审核
		api: 'antiApprove',
		method: 'post'
	},
	{
		url: '/complete', // 执行审批流 同意/驳回
		api: 'executeApprove',
		method: 'post'
	},
	{
		url: '/scm/daifa-bill/operate/batch/end', //批量结案
		api: 'finishBatch',
		method: 'post'
	},
	{
		url: '/scm/daifa-bill/operate/batch/un-end', //批量反结案
		api: 'antiFinishBatch',
		method: 'post'
	},
	{
		url: '/scm/daifa-bill/operate/batch/cancel', //批量作废
		api: 'cancelBatch',
		method: 'post'
	}
];

const MessageCenterPageApi: any = {};

apiList.forEach((item: apiFetch) => {
	MessageCenterPageApi[item.api] = (params: any) => {
		return service[item.method](`${baseUrl}${item.url}`, params);
	};
});

export { MessageCenterPageApi };
