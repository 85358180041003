import dayjs from 'dayjs';

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
	(data: moneyType): string;
}
interface QueryParams {
	[key: string]: string | string[];
}
export const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);

/**
 * 时间格式化
 * @param data
 * @param fmtType
 */
type timeType = Date | number | string;

interface ShowTimeType {
	(data: timeType, fmtType: string): string;
}
export const showTime: ShowTimeType = (data: any, fmtType: string = 'YYYY-MM-DD HH:mm:ss') =>
	data ? dayjs(data).format(fmtType) : '';

/**
 * 转化时间戳为 默认年月日 时分秒
 * @param time
 * @returns {*|string}
 */
export const formatTime = (time: any, format = 'YYYY-MM-DD HH:mm:ss') => {
	if (time) {
		return dayjs(time).format(format);
	} else {
		return '';
	}
};

/**
 * 延时工具
 * @param any
 */
export const waitTime = (time: number) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve('');
		}, time);
	});
};

/**
 * 逆转格式化
 */
export const reverseNum = (data: moneyType, len: number = 2): number | '' => {
	return data?.toString()
		? +data /
				+Array(Math.min(9, len))
					.fill('0')
					.reduce((str, num) => str + num, 1)
		: '';
};

/**
 * 格式化
 */
export const formartNum = (data: moneyType, len: number = 2): number | '' => {
	return data?.toString()
		? (+data *
				+Array(Math.min(9, len + 1))
					.fill('0')
					.reduce((str, num) => str + num, 1)) /
				10
		: '';
};

/**
 * 审核详情比对
 * @param
 * @param
 * @returns {*|string}
 */

export function comparisonInfo(judge: string, objA: any = {}, objB: any = {}) {
	if (judge == 'before') {
		return true;
	}
	return JSON.stringify(objA) === JSON.stringify(objB) || (!objA && !objB);
}

//处理html

export function formatRichText(html = '') {
	let newContent = html?.replace(/<img[^>]*>/gi, function (match, capture) {
		match = match?.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
		match = match?.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
		match = match?.replace(/height="[^"]+"/gi, '')?.replace(/height='[^']+'/gi, '');
		return match;
	});
	newContent = newContent?.replace(/style="[^"]+"/gi, function (match, capture) {
		match = match?.replace(/width:[^;]+;/gi, 'max-width:100%;')?.replace(/width:[^;]+;/gi, 'max-width:100%;');
		return match;
	});
	newContent = newContent?.replace(/<br[^>]*\/>/gi, '');
	newContent = newContent?.replace(
		/\<img/gi,
		'<img style="max-width:100%;height:auto;display:block;margin:0;vertical-align: middle;"'
	);
	return newContent;
}

export const insertValue = (arr: any[], index: number, value: any) => {
	arr.splice(index + 1, 0, value);
	return arr;
};

export const dealTreeData = (data: any) => {
	// 递归处理 树形数组
	return (
		data.length &&
		data.map((item: any) => {
			return {
				label: item.name,
				value: item.id,
				children: dealTreeData(item.childList || [])
			};
		})
	);
};

export const elementIsVisibleInViewport = (element: Element, partiallyVisible = true) => {
	// 判断元素是否在可视区域
	if (element) {
		const rect = element.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		const windowWidth = window.innerWidth || document.documentElement.clientWidth;

		// 这个地方的判断需要优化
		const vertInView = rect.top <= windowHeight && rect.top > windowHeight / 2 - 100; // 是否在屏幕中间 一半的位置

		return partiallyVisible
			? vertInView
			: rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth;
	}
};

export const throttle = (func: Function, delay: number) => {
	let lastTime = 0;
	return function () {
		const now = +new Date();
		if (now - lastTime > delay) {
			func(arguments);
			lastTime = now;
		}
	};
};

export const convertCentToYuan = (amountInCent: number): string => {
	// 金额转换
	return (amountInCent / 100).toFixed(2);
};

export const urlParamsToObject = (url: string) => {
	const params: any = new URLSearchParams(url);
	console.log(params); //URLSearchParams { 'name' => 'binjie09', 'age' => '25', 'gender' => 'male' }
	const paramObj: any = {};
	for (let [key, value] of params) {
		paramObj[key] = decodeURIComponent(value);
	}
	return paramObj;
};

export const getQueryParams = (url: string): QueryParams => {
	const queryParams: QueryParams = {};
	// 使用URL构造函数来确保我们可以正确解析URL和查询参数
	const queryString = url.match(/\?(.*)$/)?.[1];
	if (!queryString) {
		return queryParams;
	}

	// 分割查询字符串为键值对数组
	const pairs = queryString.split('&');
	for (const pair of pairs) {
		const [key, value] = pair.split('=').map(decodeURIComponent);
		if (!queryParams[key]) {
			queryParams[key] = value;
		} else if (Array.isArray(queryParams[key])) {
			(queryParams[key] as string[]).push(value);
		} else {
			queryParams[key] = [queryParams[key] as string, value];
		}
	}

	return queryParams;
};
