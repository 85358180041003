import qs, { ParsedQs } from "qs";
import dayjs from "dayjs";

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data: any) => {
  if (data) {
    return (+data / 100).toFixed(2);
  } else {
    return data;
  }
};

/**
 * 获取路径query参数
 */
 export const getQuery = (): ParsedQs =>
 qs.parse(window.location.search.substring(1));

/**
 * 运费模板区域列表格式化
 * @param data
 * @returns {*}
 */
export function formatArea(data: any) {
  return data.map((item: any) => {
    let arr = null;
    if (item.children) {
      arr = formatArea(item.children);
    }
    return {
      id: item.id,
      parentId: item.parentId,
      dictKey: item.dictKey,
      dictValue: item.dictValue,
      children: arr,
      check: false,
      freightIndex: 0,
    };
  });
}

/**
 * 格式化时间
 * @param data
 * @returns {string}
 */
export function formatTime(data: any, fmtType = "YYYY-MM-DD HH:mm:ss") {
  return data ? dayjs(data).format(fmtType) : "";
}