/*
 * @Author: your name
 * @Date: 2021-10-08 18:17:50
 * @LastEditTime: 2021-10-12 21:58:39
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /fx-member-supplier-platform/src/api/saleOrder/index.ts
 */
const saleOrder: { [key: string]: string } = {
  GET_ORDER_LIST: "/bestgift/ownSupplier/order/ownSupplierOrder",//销售单列表
  ORDER_INFO: "/bestgift/ownSupplier/order/supplierOrderInfo",//订单详情
  DELIVER_GOODS: "/bestgift/ownSupplier/order/deliverGoods",//商品发货


  ORDER_STATUS_COUNT: "/merchant/buyer/order/orderStatusCount",//订单状态角标
  DELIVERY_ORDER_STATUS_COUNT: "/merchant/order/buyer/order-delivery/getDeliveryOrderQuantity",//获取发货单数量
  GET_REASON: "/merchant/buyer/order/getReason",//买家取消订单理由
  CANCE_ORDER: "/merchant/buyer/order/cancelOrder",//买家取消订单理由
  QUERY_LOGISTIC_BY_EXPRESSNO: "/merchant/basic/express/queryLogisticByExpressNo",//物流查询
  PRE_PAY_ORDER: "/merchant/buyer/order/prePayOrder",//去支付
  RECEIVING_GOODS: "/merchant/buyer/order/receivingGoods",//去支付
  CHANGEFREIGHTINFO: "/merchant/seller/order/changeFreightInfo",//修改物流信息
  OWN_SUPPLIER_ORDER_STATUS_COUNT: "bestgift/ownSupplier/order/ownSupplierOrderStatusCount",//修改物流信息
};

export default saleOrder;